<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1"> Datalogger </v-stepper-step>

      <v-divider />

      <v-stepper-step :complete="e1 > 2" step="2"> Cihaz tipi </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3"> Veri </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row class="pt-4" align="center">
          <v-col cols="12" sm="6">
            <v-select
              v-model="santralModel"
              outlined
              dense
              :items="santralList"
              :menu-props="{ maxHeight: '300' }"
              label="santral"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="dataloggerModel"
              outlined
              :items="dataloggerList"
              :menu-props="{ maxHeight: '300' }"
              label="Datalogger"
              dense
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="stringboxTypeModel"
              :items="inverterTypeList"
              :menu-props="{ maxHeight: '300' }"
              label="Cihaz Listesi"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-btn color="primary" @click="e1 = 2" dense outlined> Sonraki </v-btn>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row class="pt-4" align="center">
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.stringboxDeviceSerial"
              label="Serial"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.STRINGBOXNAME"
              label="STRINGBOXNAME"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field v-model="formdata.ID" label="ID" outlined dense />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.STRINGBOX"
              label="STRINGBOX"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-select
              v-model="formdata.stringboxStatus"
              :items="['Active', 'Passive']"
              :menu-props="{ maxHeight: '300' }"
              label="Durum"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.stringQuantity"
              label="stringQuantity"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.voltageQuantity"
              label="voltageQuantity"
              outlined
              dense
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.stringPanelQuantity"
              label="stringPanelQuantity"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-4">
          <v-btn class="ml-2 mb-4 ml-2" @click="addMttpFunc">
            <v-icon>mdi-add</v-icon>
            Mttp Ekle
          </v-btn>
          <template v-for="(mttp, k) in mttpList">
            <div class="col-12 d-flex mt-0 pt-0 pt-0 pb-0" :key="k">
              <v-text-field
                v-model="mttpList[k].text"
                label="Mttp"
                class="col-6 mr-2"
                outlined
                dense
                hiden-details
                hiden-footer
                hiden-hint
              />

              <v-select
                v-model="mttpList[k].dcvoltage"
                :items="deviceParameterList"
                :menu-props="{ maxHeight: '300' }"
                label="Voltage Listesi"
                class="col-6 ml-2"
                outlined
                multiple
                hiden-details
                hiden-footer
                hiden-hint
                dense
              >
              </v-select>
            </div>
          </template>
        </v-row>
        <v-row class="ml-5 mb-4 mt-4">
          <v-btn color="primary" @click="e1 = 3" dense outlined>
            Sonraki
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-row class="mb-0 mt-0 pt-0 pb-0">
          <v-col cols="12">
            <v-data-table
              v-model="deviceParameterModel"
              :headers="devheaders"
              :items="deviceParameterList"
              class="elevation-1"
              dense
              item-key="value"
              show-select
              :items-per-page="itemPerPage"
            />
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-btn color="primary" @click="e1 = 1" dense outlined>
              Sonraki
            </v-btn>
            <hr />
            <hr />
            <hr />
            <v-btn @click="setStrinboxCreate" dense outlined> Kaydet </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { getDataloggerList } from "@/api/Request/datalogger";
import { getInverterTypeList } from "@/api/Request/inverter";
import { setStringbox } from "@/api/Request/stringbox";
import { getDeviceList } from "@/api/Request/farm";
import { getSantralList } from "@/api/Request/santral";
import helper from "@/api/helper";
export default {
  props: ["stringdata"],
  data: () => ({
    e1: 1,
    deviceTypeModel: "STRINGBOX",
    stringboxTypeModel: "",
    inverterTypeList: [],
    inverterTypeData: [],
    itemPerPage: 10,
    deviceParameterList: [],
    deviceParamHeader: [],
    deviceParameterModel: [],
    dataloggerList: [],
    devheaders: [],
    dataloggerModel: "",
    deviceList: [],
    deviceModel: "",
    dataloggerData: [],
    santralList: [],
    santralData: {},
    santralModel: "",
    stringboxTypeDeviceId: "",
    mttpList: [],
    mttpAddModel: [],
    formdata: {},
    mttpIndex: 0,
  }),
  watch: {
    deviceTypeModel() {
      // this.getInverterTypeList();
    },
    deviceParameterModel() {},
    dataloggerModel() {
      this.getInverterTypeList();
    },
    stringboxTypeModel() {
      this.getInverterType();
    },

    santralModel() {
      if (this.santralData.length > 0) {
        this.formdata.santral = this.santralData[this.santralModel].santral;
      }

      this.getDatalogger();
    },
    stringdata() {
      
      this.santralModel = "";
      this.dataloggerModel = "";
      this.stringboxTypeModel = "";
      this.santralData = {};

      this.newStringData();
      this.deviceParameterList = [];
      this.deviceParameterModel = [];
      this.e1 = 1;
      this.deviceTypeModel = "STRINGBOX";
      this.deviceModel = "";
      this.setInverterDataFunc();
    },
  },
  mounted() {
    const storage = window.localStorage;
    // eslint-disable-next-line camelcase
    const user_company = JSON.parse(
      window.atob(storage.getItem("user_company"))
    );
    this.companyCode = user_company.companyCode;
    this.prefix = user_company.prefix;
    this.newStringData();
    this.getSantralList();

    this.setInverterDataFunc();
  },
  methods: {
    addMttpFunc() {
      this.mttpIndex++;
       
      let keys = "MTTP" + this.mttpIndex;
      // this.formdata.mttpList[keys] = []
      this.mttpList.push({
        text: keys,
        dcvoltage: [],
      });
       
    },
    newStringData() {
      this.formdata = {
        stringPanelQuantity: "",
        voltageQuantity: "",
        stringQuantity: "",
        stringboxStatus: "",
        STRINGBOX: "",
        STRINGBOXNAME: "",
        stringboxDeviceSerial: "",
        stringboxTypeModel: "",
        dataloggerModel: "",
        santralModel: "",
        ID: "",
        deviceParameterModel: "",
        MPPT: [],
      };
    },
    setInverterDataFunc() {
      if (this.stringdata.general_measure_list !== undefined) {
        this.devheaders = [
          { text: "text", value: "value" },
          { text: "key", value: "key" },
          { text: "unit", value: "unit" },
          { text: "type", value: "type" },
          { text: "formuletype", value: "formuletype" },
        ];
        this.deviceParameterList = this.stringdata.general_measure_list;
        this.deviceParameterModel = this.stringdata.general_measure_list;
        this.stringboxTypeModel = this.stringdata.stringboxTypeDeviceId;

        this.formdata = { ...{}, ...this.stringdata };

        this.santralModel = this.stringdata.santral;
        this.dataloggerModel = this.stringdata.dataloggerName;
        this.mttpList = this.stringdata.MPPT;
      }
    },
    getSantralList() {
      const params1 = {
        condiniton: { prefix: this.prefix },
      };

      getSantralList(params1).then((res) => {
        const santralList = [];
        const santralData = {};

        Object.keys(res.data.data).forEach((k) => {
          santralData[res.data.data[k].santral] = res.data.data[k];
          santralList.push({
            text: res.data.data[k].santral,
            value: res.data.data[k].santral,
          });
        });
        this.santralData = santralData;
        this.santralList = santralList;
      });
    },
    // datalogger listesi
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
          santral: this.santralModel,
        },
      };
      getDataloggerList(params).then((res) => {
        const devList = [];
        const deviceList = {};

        if (res.data.data.length === 0) {
          return;
        }

        Object.keys(res.data.data).forEach((i) => {
          deviceList[res.data.data[i].deviceName] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].deviceName}`,
            value: `${res.data.data[i].deviceName}`,
          });
        });

        this.dataloggerData = deviceList;
        this.dataloggerList = devList;
      });
    },

    /// / inverter tipleri listesi
    getInverterTypeList() {
      const params = {
        condiniton: {
          DeviceType: this.deviceTypeModel,
        },
      };

      getDeviceList(params).then((res) => {
        const devList = [];
        const deviceData = {};
        // this.deviceData = res.data.data;

        Object.keys(res.data.data).forEach((i) => {
          const dev = res.data.data[i].DeviceId;
          deviceData[dev] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].DeviceId,
          });
        });

        this.inverterTypeList = devList;
        this.deviceData = deviceData;
      });
    },

    getInverterType() {
      this.devheaders = [
        { text: "text", value: "text" },
        { text: "key", value: "key" },
        { text: "unit", value: "unit" },
        { text: "type", value: "type" },
        { text: "formuletype", value: "formuletype" },
      ];

      if (helper.is_Empty(this.dataloggerModel)) {
        return;
      }

      if (helper.is_Empty(this.deviceData)) {
        return;
      }

      const params = {
        condiniton: {
          STRINGBOXMODEL: this.deviceData[this.stringboxTypeModel].Model,
        },
      };

      if (this.dataloggerData[this.dataloggerModel] !== undefined) {
        const temp = this.dataloggerData[this.dataloggerModel];
        params.condiniton.Datalogger = temp.Model;
      }

      getInverterTypeList(params).then((res) => {
        // console.log(res);
        this.deviceParameterList = res.data.data[0].general_measure_list;
        this.deviceParameterModel = res.data.data[0].general_measure_list;

        this.itemPerPage = this.deviceParameterList.length;
      });
    },

    setStrinboxCreate() {
      const temp = this.dataloggerData[this.dataloggerModel];

      const data = {
        STRINGBOXNAME: this.formdata.STRINGBOXNAME,
        ID: this.formdata.ID,
        santral: this.santralModel,
        STRINGBOX: this.formdata.STRINGBOX,
        stringboxStatus: this.formdata.stringboxStatus,
        STRINGBOXMODEL: this.deviceData[this.stringboxTypeModel].Model,
        stringboxTypeDeviceId:
          this.deviceData[this.stringboxTypeModel].DeviceId,
        stringboxManufacture:
          this.deviceData[this.stringboxTypeModel].DeviceManufacture,
        stringboxDeviceModel:
          this.deviceData[this.stringboxTypeModel].DeviceModel,
        stringboxDeviceSerial: this.formdata.stringboxDeviceSerial,
        stringQuantity: this.formdata.stringQuantity,
        voltageQuantity: this.formdata.voltageQuantity,
        stringPanelQuantity: this.formdata.stringPanelQuantity,
        general_measure_list: this.deviceParameterModel,
        dataloggerName: temp.deviceName,
        dataloggerDeviceModel: temp.DeviceModel,
        dataloggerTypeDeviceId: temp.DeviceId,
        santralCode: temp.santralCode,
        timeInterval: temp.timeInterval,
        MPPT: this.mttpList,
      };

      // this.formdata

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          deviceType: this.deviceTypeModel,
          STRINGBOX: data.STRINGBOX,
          santral: data.santral,
          santralCode: data.santralCode,
        },
        data,
      };

      setStringbox(params).then((res) => {
        // console.log(res);
      });
    },
  },
};
</script>
